import rollbar, { ROLLBAR_FILTER_CONFIG } from "~/utils/rollbar.client";
import type { ErrorInfo } from "react-dom/client";

const { hydrationErrors } = ROLLBAR_FILTER_CONFIG;
const warningMessage = "React recovered from an error during hydration.";

const shouldFilterError = (error: unknown): boolean => {
  if (!hydrationErrors.shouldFilter) return false;

  let message = "";
  if (error instanceof Error) {
    message = error.message;
  } else if (typeof error === "string") {
    message = error;
  }

  if (message === "") return false;

  return hydrationErrors.ignoreList.some((ignoredError) => ignoredError.test(message));
};

// This overrides the default onRecoverableError handler in hydrateRoot so we can filter out
// errors that React was able to recover from during hydration and not send them to Rollbar.
export function recoverableErrorHandler(error: unknown, errInfo: ErrorInfo) {
  console.warn(warningMessage, error);

  if (shouldFilterError(error)) return;

  rollbar.warn(warningMessage, error as any, errInfo);
}
