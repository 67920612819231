export default {
  // This is the list of languages your application supports
  supportedLngs: [
    "en",
    "es",
    "de",
    "fi",
    "fr",
    "he",
    "it",
    "ja",
    "ko",
    "nl",
    "no",
    "pl",
    "pt",
    "ru",
    "sv",
    "tg",
    "th",
    "zh",
    "zhHant",
  ],
  // This is the language you want to use in case
  // if the user language is not in the supportedLngs
  fallbackLng: "en",
  // The default namespace of i18next is "translation", but you can customize it here
  defaultNS: "common",
};
